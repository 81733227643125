import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StandardCollection12Promos from '../promo/collections/StandardCollection12Promos';
import StandardCollection6Promos from '../promo/collections/StandardCollection6Promos';
import PageHeader from '../generic/PageHeader';
import Hero from '../promo/Hero';
import { occasionShape } from './shapes';
import { selectUpcomingOccasions, selectOtherOccasions, selectHeroOccasion } from './reducer';
import { heroShape } from '../promo/shapes';
import { formatOccasionDate } from './utils';
import ContentSeparator from '../generic/ContentSeparator';
import MetaProperties from '../generic/MetaProperties';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

export const Page = ({ upcomingOccasions, otherOccasions, heroOccasion }) => {
  const parseOccasion = ({ id, name, startDate, endDate }) => ({
    id,
    title: name,
    subtitle: formatOccasionDate(startDate, endDate),
    imageId: `food/ic/food_16x9_{width}/occasions/${id}_16x9.jpg`,
    url: `/food/occasions/${id}`,
    type: 'Occasion',
  });

  return (
    <>
      <div className="occasion-page-wrapper occasions-with-dates">
        <MetaProperties title="Occasions" description="Occasions throughout the year" />
        <PageHeader className="occasions__title" title="Food occasions within the year" />
        <Hero {...heroOccasion} />
        <StandardCollection12Promos
          id="occasions-with-dates-collection"
          promos={upcomingOccasions.map(parseOccasion)}
          promoTitleClassName="gel-pica-bold"
          maxCollectionSize={upcomingOccasions.length}
        />
        <ContentSeparator />
      </div>
      <div className="food-body__grey-background">
        <StandardCollection6Promos
          id="other-occasions-collection"
          title="Other occasions throughout the year"
          promos={otherOccasions.map(parseOccasion)}
          maxCollectionSize={otherOccasions.length}
        />
        <ContentSeparator />
      </div>
    </>
  );
};

Page.propTypes = {
  upcomingOccasions: PropTypes.arrayOf(PropTypes.shape(occasionShape)).isRequired,
  otherOccasions: PropTypes.arrayOf(PropTypes.shape(occasionShape)).isRequired,
  heroOccasion: PropTypes.shape(heroShape).isRequired,
};

export default connect(state => ({
  upcomingOccasions: selectUpcomingOccasions(state),
  otherOccasions: selectOtherOccasions(state),
  heroOccasion: selectHeroOccasion(state),
}))(Page);
