import PropTypes from 'prop-types';

export const occasionShape = {
  id: PropTypes.string.isRequired,
  startDate: PropTypes.number,
  hasImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  endDate: PropTypes.number,
};
