import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Collection from '../Collection';
import Promo from '../Promo';
import { promoShape } from '../shapes';

if (process.env.CLIENT) {
  require('./StandardCollection6Promos.scss'); // eslint-disable-line global-require
}

const StandardCollection6Promos = ({ id, maxCollectionSize, promos, title, TitleElement }) => (
  <Collection
    collectionClassName="standard-6-promos"
    id={id}
    TitleElement={TitleElement}
    title={title}
  >
    {promos
      .slice(0, maxCollectionSize)
      .map(({ id: promoId, imageId, title: promoTitle, synopsis, subtitle, type, url }) => (
        <div key={promoId} className="gel-layout__item gel-1/2 gel-1/3@m gel-1/2@xl gel-1/3@xxl">
          <Promo
            extraClasses={{
              content: isEmpty(imageId) ? 'gel-1/1' : 'gel-1/1 gel-1/2@xl',
              image: 'gel-1/1 gel-1/2@xl',
              type: 'promo__type-right@xl',
            }}
            collectionId={id}
            collectionTitle={title}
            id={promoId}
            imageId={imageId}
            synopsis={synopsis}
            subtitle={subtitle}
            title={promoTitle}
            type={type}
            url={url}
          />
        </div>
      ))}
  </Collection>
);

StandardCollection6Promos.propTypes = {
  id: PropTypes.string.isRequired,
  maxCollectionSize: PropTypes.number,
  promos: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  title: PropTypes.string.isRequired,
  TitleElement: PropTypes.node,
};

StandardCollection6Promos.defaultProps = {
  maxCollectionSize: 6,
  TitleElement: 'h2',
};

export default StandardCollection6Promos;
